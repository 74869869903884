var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-section users"},[_c('div',{staticClass:"filters"},[_c('label',[_vm._v("Filter by")]),_c('ul',[_c('li',[_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown"}},[_vm._v(_vm._s(_vm.filter_label('active')))]),_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",class:{ active: _vm.filters.active === true },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.filters.active = !_vm.filters.active}}},[_vm._v("Active users")])])])]),(_vm.me.is_admin)?_c('li',[_c('user-groups-filter',{attrs:{"label":_vm.filter_label('groups')},model:{value:(_vm.filters.user_groups),callback:function ($$v) {_vm.$set(_vm.filters, "user_groups", $$v)},expression:"filters.user_groups"}})],1):_vm._e(),_c('li',[_c('user-segment-filter',{attrs:{"label":_vm.filter_label('segments')},model:{value:(_vm.filters.user_segments),callback:function ($$v) {_vm.$set(_vm.filters, "user_segments", $$v)},expression:"filters.user_segments"}})],1)])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"table-wrap"},[(_vm.activity !== null)?[(_vm.activity.length > 0)?_c('ht-sortable-table',{attrs:{"rows":_vm.activity,"columns":[
            'User',
            'Email',
            'Last activity',
            'Redeemable points',
            'Shoutout allowance',
            'Award allowance',
          ],"sortable":{}},scopedSlots:_vm._u([{key:"rows",fn:function({rows, sort_by}){return _vm._l((rows),function(row,index){return _c('tr',{key:`activity_row_${sort_by}_${index}`},[_c('td',[_c('user-card',{attrs:{"user":row,"height":22}})],1),_c('td',{staticClass:"email"},[_vm._v(" "+_vm._s(row.email)+" "),(row.alt_email)?_c('small',[_vm._v(_vm._s(row.alt_email))]):_vm._e()]),_c('td',{staticClass:"last-activity"},[_vm._v(" "+_vm._s(_vm.lastActivity(row))+" "),_c('small',[_vm._v(_vm._s(_vm.lastActivity(row, true)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.$formatPoints(row.redeemable_points))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$formatPoints(row.shoutout_allowance_remaining, true))+"/"),_c('small',[_vm._v(_vm._s(_vm.$formatPoints(row.shoutout_allowance)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.$formatPoints(row.award_allowance_remaining, true))+"/"),_c('small',[_vm._v(_vm._s(_vm.$formatPoints(row.award_allowance)))])])])})}}],null,false,95882985)}):_c('div',{staticClass:"empty-state"},[_vm._v(" No users found with these filters. ")])]:_c('loading-indicator')],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }