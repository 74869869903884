<template>
  <section class="main-section users">
    <div class="filters">
      <label>Filter by</label>
      <ul>
        <li>
          <div class="dropdown">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{
              filter_label('active')
            }}</a>

            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                @click.prevent.stop="filters.active = !filters.active"
                :class="{ active: filters.active === true }"
                href="#"
                >Active users</a
              >
            </div>
          </div>
        </li>
        <li v-if="me.is_admin">
          <user-groups-filter
            v-model="filters.user_groups"
            :label="filter_label('groups')"
          />
        </li>
        <li>
          <user-segment-filter
            v-model="filters.user_segments"
            :label="filter_label('segments')"
          />
        </li>
      </ul>
    </div>

    <div class="content">
      <div class="table-wrap">
        <template v-if="activity !== null">
          <ht-sortable-table
            v-if="activity.length > 0"
            :rows="activity"
            :columns="[
              'User',
              'Email',
              'Last activity',
              'Redeemable points',
              'Shoutout allowance',
              'Award allowance',
            ]"
            :sortable="{}"
          >
            <template #rows="{rows, sort_by}">
              <tr
                v-for="(row, index) in rows"
                :key="`activity_row_${sort_by}_${index}`"
              >
                <td>
                  <user-card :user="row" :height="22" />
                </td>
                <td class="email">
                  {{ row.email }}
                  <small v-if="row.alt_email">{{ row.alt_email }}</small>
                </td>
                <td class="last-activity">
                  {{ lastActivity(row) }}
                  <small>{{ lastActivity(row, true) }}</small>
                </td>
                <td>
                  {{ $formatPoints(row.redeemable_points) }}
                </td>
                <td>
                  {{
                    $formatPoints(row.shoutout_allowance_remaining, true)
                  }}/<small>{{ $formatPoints(row.shoutout_allowance) }}</small>
                </td>
                <td>
                  {{
                    $formatPoints(row.award_allowance_remaining, true)
                  }}/<small>{{ $formatPoints(row.award_allowance) }}</small>
                </td>
              </tr>
            </template>
          </ht-sortable-table>
          <div class="empty-state" v-else>
            No users found with these filters.
          </div>
        </template>
        <loading-indicator v-else />
      </div>
    </div>
  </section>
</template>

<script>
import { debounce } from 'lodash';

import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups';
import UserSegmentFilter from '@/components/Reporting/Filters/UserSegments';

export default {
  props: ['dateRange'],
  components: {
    UserGroupsFilter,
    UserSegmentFilter,
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      activity: null,
      filters: {
        active: true,
        user_groups: [],
        user_segments: [],
      },
    };
  },
  watch: {
    dateRange(val) {
      this.filters.date_range = val;
    },
    filters: {
      deep: true,
      handler: function() {
        this.populateActivity();
      },
    },
  },
  beforeDestroy() {
    this.$parent.$off('exportTable', this.export);
  },
  created() {
    if (this.$route.query.group)
      this.filters.user_groups = [this.$route.query.group.toString()];

    this.populateActivity();

    this.$parent.$on('exportTable', this.export);
  },
  methods: {
    populateActivity: debounce(async function() {
      this.activity = null;
      const resp = await this.$api.Reporting.get_users(this.filters, true);
      this.activity = resp;
    }, 250),
    filter_label(filter) {
      switch (filter) {
        case 'segments':
          return this.filters.user_segments.length > 0
            ? `Segments (${this.filters.user_segments.length})`
            : 'Segments';
        case 'active':
          return this.filters.active === true ? `Active users` : 'All users';
        case 'sent_by':
          return this.filters.sent_by_segments.length > 0
            ? `Sent by (${this.filters.sent_by_segments.length})`
            : 'Sent by';
        case 'received_by':
          return this.filters.received_by_segments.length > 0
            ? `Received by (${this.filters.received_by_segments.length})`
            : 'Received by';
        case 'groups':
          return this.filters.user_groups.length > 0
            ? `Channels (${this.filters.user_groups.length})`
            : 'Channels';
        case 'values':
          return this.filters.values.length > 0
            ? `Values (${this.filters.values.length})`
            : 'Values';
      }
    },
    privacyLabel(v) {
      switch (v) {
        case 'PRIVATE':
          return 'Private';
        case 'SKIP':
          return 'Not Celebrating';
        default:
          return 'Public';
      }
    },
    export() {
      const custom_fields = Object.entries(this.activity[0]).reduce(
        (agg, [key, val]) => {
          if (key.substr(0, 3) === 'CF_') agg.push(key.substr(3));
          return agg;
        },
        []
      );

      window.generateCSV('HiThrive Users Report.csv', [
        [
          'User',
          'Status',
          'Email',
          'Alternate Email',
          'Is Admin',
          'Date of Birth',
          'Anniversary Date',
          'Birthday Privacy',
          'Groups',
          'Segments',
          'Redeemable Points',
          'Award Allowance',
          'Award Allowance Remaining',
          'Shoutout Allowance',
          'Shoutout Allowance Remaining',
          'Shoutout Last Sent',
          'Shoutout Last Received',
          'Award Last Sent',
          'Award Last Received',
          ...custom_fields,
        ],
        ..._.map(this.activity, (r) => [
          r.name,
          r.has_group === true ? 'Active' : 'Inactive',
          r.email,
          r.alt_email,
          r.is_admin === true ? 'Yes' : 'No',
          `${r.birthday_month + 1}/${r.birthday_day}`,
          `${r.anniversary_year}-${r.anniversary_month + 1}-${
            r.anniversary_day
          }`,
          this.privacyLabel(r.metadata.birthday_public),
          r.groups.map((g) => g.title).join(', '),
          r.user_segments.map((g) => g.name).join(', '),
          this.$formatPoints(r.redeemable_points),
          r.award_allowance,
          r.award_allowance_remaining,
          r.shoutout_allowance,
          r.shoutout_allowance_remaining,
          r.shoutout_last_sent
            ? this.$formatDate(r.shoutout_last_sent, this.$DateTime.DATE_SHORT)
            : '',
          r.shoutout_last_received
            ? this.$formatDate(
                r.shoutout_last_received,
                this.$DateTime.DATE_SHORT
              )
            : '',
          r.award_last_sent
            ? this.$formatDate(r.award_last_sent, this.$DateTime.DATE_SHORT)
            : '',
          r.award_last_received
            ? this.$formatDate(r.award_last_received, this.$DateTime.DATE_SHORT)
            : '',
          ...Object.entries(r).reduce((agg, [k, v]) => {
            if (k.substr(0, 3) === 'CF_') agg.push(v);
            return agg;
          }, []),
        ]),
      ]);
    },
    lastActivity(user, raw_date = false) {
      const dates = [user.shoutout_last_sent, user.award_last_sent];

      if (_.every(dates, (d) => d === null)) return 'never';

      dates.sort(
        (a, b) => this.$DateTime.fromISO(b) - this.$DateTime.fromISO(a)
      );

      const most_recent = this.$DateTime.fromISO(dates[0]);
      if (raw_date === true)
        return this.$formatDate(most_recent, this.$DateTime.DATE_SHORT);
      if (this.$today - most_recent < 86400000) return 'today';
      return most_recent.toRelative({ unit: 'days' });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 25px 0 0;
}
.table-wrap {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $light;

  ::v-deep > table {
    font-size: 12px;
    margin: 0;

    .email {
      line-height: 13px;
      > small {
        display: block;
        color: $muted-text;
      }
    }

    .last-activity {
      line-height: 13px;
      > small {
        display: block;
        color: $muted-text;
      }
    }

    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>
